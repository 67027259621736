import React from 'react';

import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			'background': theme.palette.primary.main,
			'color': 'white',
			'&:hover': {
				background: theme.palette.primary.dark,
			},
		},
	})
);
interface AddVideoSessionButtonProps {
	onClick?: () => void;
	className?: string;
}

export default function AddVideoSessionButton({ onClick, className }: AddVideoSessionButtonProps) {
	const classes = useStyles();

	return (
		<Button onClick={onClick} className={clsx(classes.button, className)} data-cy-disconnect>
			Add Video Session
		</Button>
	);
}
