import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Person } from '@mui/icons-material';
import { Typography, Grid, Hidden, Popover } from '@mui/material';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Participant } from 'twilio-video';

import PersonIcon from '../assets/icons/PersonIcon';
import RoshalLogo from '../assets/icons/RoshalLogo';
import { OrderState, SystemRole } from '../common/constants';
import { OrderInfo } from '../common/types';
import { isMobile, format_phone } from '../common/utils';
import { format_participant_id } from '../common/utils';
import useParticipants from '../hooks/useParticipants';
import useRoomState from '../hooks/useRoomState';
import useVideoContext from '../hooks/useVideoContext';
import InviteButton from './Buttons/InviteButton';
import MyDevicesButton from './Buttons/MyDevicesButton';
import SignOutButton from './Buttons/SignOutButton';
import ToggleAudioButton from './Buttons/ToggleAudioButton';
import ToggleScreenShareButton from './Buttons/ToggleScreenShareButton';
import ToggleVideoButton from './Buttons/ToggleVideoButton';
import ConfirmationDialog from './ConfirmationDialog/ConfirmationDialog';
import InviteDialog from './InviteDialog/InviteDialog';
import Menu from './Menu/Menu';
import MobileMenu from './Menu/MobileMenu';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			backgroundColor: theme.palette.grey[200],
			top: 0,
			left: 0,
			right: 0,
			height: `30px`,
			position: 'fixed',
			display: 'flex',
			padding: '0 1.43em',
			zIndex: 10,
			[theme.breakpoints.down('md')]: {
				height: `56px`,
				padding: 0,
			},
		},
		container: {
			backgroundColor: theme.palette.background.default,
			top: 30,
			left: 0,
			right: 0,
			height: `72px`,
			position: 'fixed',
			display: 'flex',
			padding: '0.3em',
			zIndex: 10,
			[theme.breakpoints.down('md')]: {
				height: `0px`,
				padding: 0,
			},
		},
		logo: {
			top: 0,
			left: 0,
			padding: '0 0.25em',
			height: '24px',
			width: 'auto',
			fill: theme.palette.primary.main,
		},
		screenShareBanner: {
			'position': 'fixed',
			'zIndex': 8,
			'top': 75,
			'bottom': `0px`,
			'left': 0,
			'right': 0,
			'height': '104px',
			'background': 'rgba(0, 0, 0, 0.5)',
			'& h6': {
				color: 'white',
			},
			'& button': {
				'background': 'white',
				'color': theme.brand,
				'border': `2px solid ${theme.brand}`,
				'margin': '0 2em',
				'&:hover': {
					color: '#600101',
					border: `2px solid #600101`,
					background: '#FFE9E7',
				},
			},
		},
		personIcon: {
			height: '24px',
			width: '24px',
			marginLeft: '0.5em',
			marginBottom: '0.5em',
			marginRight: '0.5em',
			fill: theme.palette.grey[500],
		},
		signoutButton: {
			height: '24px',
			width: 'auto',
			marginLeft: '0.5em',
			marginRight: '0.5em',
			button: {
				'background': theme.danger,
				'color': theme.danger,
				'border': `none`,
				'margin': '0 2em',
				'&:hover': {
					background: theme.palette.grey[400],
				},
			},
			[theme.breakpoints.down('md')]: {
				marginBottom: 20,
			},
		},
		startServiceButton: {
			'height': '40px',
			'width': '150px',
			'marginRight': '10px',
			'background': theme.palette.success.main,
			'color': 'white',
			'fontSize': '1.0rem',
			'&:hover': {
				background: theme.palette.success.dark,
			},
		},
		endServiceButton: {
			'height': '40px',
			'width': '150px',
			'marginRight': '10px',
			'background': 'red',
			'color': 'white',
			'fontSize': '1.0rem',
			'&:hover': {
				background: '#600101',
			},
		},
		readyButton: {
			'height': '40px',
			'width': '110px',
			'marginRight': '10px',
			'background': theme.palette.success.main,
			'color': 'white',
			'fontSize': '1.0rem',
			'&:hover': {
				background: theme.palette.success.dark,
			},
		},
		inviteButton: {
			marginRight: '10px',
		},
		hideMobile: {
			display: 'initial',
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
		contactButton: {
			marginLeft: '10px',
		},
		contactText: {
			margin: '6px',
			fontSize: '1.1rem',
		},
	})
);

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function MenuBar() {
	const classes = useStyles();
	const {
		isSharingScreen,
		toggleScreenShare,
		setIsMyDevicesSelectionOpen,
		setIsBackgroundSelectionOpen,
	} = useVideoContext();
	const [inviteOpen, setInviteOpen] = useState(false);
	const [confirmDisconnectOpen, setConfirmDisconnectOpen] = useState(false);
	const [confirmEndServiceOpen, setConfirmEndServiceOpen] = useState(false);
	const [hideReadyButton, setHideReadyButton] = useState<boolean>(true);
	const [disableReadyButton, setDisableReadyButton] = useState<boolean>(false);
	const [hideStartServiceButton, setHideStartServiceButton] = useState<boolean>(true);
	const [hideEndServiceButton, setHideEndServiceButton] = useState<boolean>(true);
	const [orderInfo, setOrderInfo] = useState<OrderInfo>();
	// Contact consts
	const [contactNameText, setContactNameText] = useState<string>('');
	const [contactPhoneText, setContactPhoneText] = useState<string>('');
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'contact-popover' : undefined;

	const query = useQuery();
	const userRole = query.get('role') || '';
	const roomState = useRoomState();
	const isReconnecting = roomState === 'reconnecting';
	const { room } = useVideoContext();
	const participants = useParticipants();

	// Contact Popover Events
	const handleContactClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleContactClose = () => {
		setAnchorEl(null);
	};

	const initializeContactText = () => {
		if (orderInfo) {
			switch (userRole) {
				case SystemRole.GUIDE:
					setContactNameText('Operator Name: ' + orderInfo.contact.operator.name || '');
					setContactPhoneText(
						'Operator Phone: ' + format_phone(orderInfo.contact.operator.phone) || ''
					);
					break;
				case SystemRole.OPERATOR:
					setContactNameText('Guide Name: ' + orderInfo.contact.guide.name || '');
					setContactPhoneText('Guide Phone: ' + format_phone(orderInfo.contact.guide.phone) || '');
					break;
				default:
					setContactNameText('Guide Name: ' + orderInfo.contact.guide.name || '');
					setContactPhoneText('Guide Phone: ' + format_phone(orderInfo.contact.guide.phone) || '');
					break;
			}
		}
	};

	async function getOrderInfo() {
		console.log('getOrderInfo');
		try {
			const response = await fetch(`/api/order?order_gid=${room.name}`, {
				method: 'GET',
			});

			const result = await response.json();
			setOrderInfo(result);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	function sendSignal(action: string) {
		console.log(`sendSignal - ${action}`);
		return fetch(`/api/signal?order_gid=${room.name}&action=${action}`, {
			method: 'POST',
		});
	}

	function readyNotification() {
		console.log('readyNotification');
		sendSignal(':operator_ready');

		setDisableReadyButton(true);
		setTimeout(() => {
			setDisableReadyButton(false);
		}, 10000);
	}

	function guideConnected() {
		console.log('guideConnected');
		sendSignal(':guide_connected');
	}

	function operatorConnected() {
		console.log('operatorConnected');
		sendSignal(':operator_connected');
	}

	function startService() {
		console.log('startService');
		sendSignal(':start_service').then(async res => {
			if (res.ok) {
				setHideStartServiceButton(true);
				setHideEndServiceButton(false);
			}
		});
	}

	function endService() {
		console.log('endService');
		sendSignal(':end_service').then(async res => {
			if (res.ok) {
				setHideStartServiceButton(true);
				setHideEndServiceButton(true);

				console.log('endService - disconnect');
				room?.disconnect();
			}
		});
	}

	function showReady() {
		if (userRole !== SystemRole.OPERATOR) {
			return;
		}

		let isGuideJoined = false;
		room.participants.forEach((participant: Participant) => {
			if (participant.identity.includes(SystemRole.GUIDE)) {
				// This participant is the guide
				isGuideJoined = true;
			}
		});
		isGuideJoined ? setHideReadyButton(true) : setHideReadyButton(false);
	}

	function showStartEndService() {
		const isGuide = userRole === SystemRole.GUIDE;
		if (!isGuide) {
			console.log('showStartEndService - not guide');
			setHideStartServiceButton(true);
			setHideEndServiceButton(true);
			return;
		}

		if (orderInfo?.state?.code < OrderState.SERVICING) {
			console.log('showStartEndService - show start');
			setHideStartServiceButton(false);
		} else {
			console.log('showStartEndService - hide start');
			setHideStartServiceButton(true);
		}

		if (orderInfo?.state?.code === OrderState.SERVICING) {
			console.log('showStartEndService - show end');
			setHideEndServiceButton(false);
		} else {
			console.log('showStartEndService - hide end');
			setHideEndServiceButton(true);
		}
	}

	function sendConnectedSignal() {
		switch (userRole) {
			case SystemRole.GUIDE:
				guideConnected();
				break;
			case SystemRole.OPERATOR:
				operatorConnected();
				break;
		}
	}

	useEffect(() => {
		getOrderInfo();
		sendConnectedSignal();
	}, []);

	useEffect(() => {
		showStartEndService();
	}, [orderInfo]);

	useEffect(() => {
		showReady();
	}, [participants]);

	useEffect(() => {
		initializeContactText();
	}, [orderInfo]);

	return (
		<>
			<div className={classes.title}>
				<Grid container justifyContent="space-around" alignItems="center">
					{/* <Hidden smDown> */}
					<Grid style={{ flex: 1, margin: 2 }}>
						<Hidden mdDown>
							<RoshalLogo className={classes.logo} />
						</Hidden>
						<Hidden mdUp>
							<MobileMenu
								isReadyHidden={hideReadyButton}
								isStartServiceHidden={hideStartServiceButton}
								isEndServiceHidden={hideEndServiceButton}
								onInviteClicked={() => setInviteOpen(true)}
								onContactClicked={event => handleContactClick(event)}
								onReadyClicked={() => readyNotification()}
								onStartServiceClicked={() => startService()}
								onEndServiceClicked={() => endService()}
							/>
						</Hidden>
					</Grid>
					<Grid style={{ flex: 1, paddingRight: 5 }}>
						<Grid container justifyContent="flex-end">
							<Hidden mdDown>
								<Typography variant="body1">
									{`${format_participant_id(room?.localParticipant?.identity)}` || 'Participant'}
								</Typography>
								<PersonIcon className={classes.personIcon} />
							</Hidden>
							<SignOutButton
								className={classes.signoutButton}
								onClick={() => setConfirmDisconnectOpen(true)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{isSharingScreen && (
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					className={classes.screenShareBanner}
				>
					<Typography variant="h6">You are sharing your screen</Typography>
					<Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
				</Grid>
			)}
			<header className={classes.container}>
				<Grid container justifyContent="space-around" alignItems="center">
					<Hidden mdDown>
						<Grid style={{ flex: 1, paddingLeft: 6 }}>
							<Typography variant="body1">
								<MyDevicesButton
									onClick={() => {
										setIsMyDevicesSelectionOpen(true);
										setIsBackgroundSelectionOpen(false);
									}}
								/>
								<Button className={classes.contactButton} onClick={handleContactClick}>
									<Person /> Contact
								</Button>
							</Typography>
						</Grid>
					</Hidden>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleContactClose}
						style={{ marginTop: 10 }}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<div style={{ marginTop: 10 }} />
						<Typography className={classes.contactText}>{contactNameText}</Typography>
						<Typography className={classes.contactText}>{contactPhoneText}</Typography>
						<Typography className={classes.contactText}>
							For Support: 1-888-Roshal0 (767-4250)
						</Typography>
					</Popover>
					<Grid item>
						<Grid container justifyContent="center">
							<ToggleAudioButton disabled={isReconnecting} />
							<ToggleVideoButton disabled={isReconnecting} />
							{!isSharingScreen && !isMobile && (
								<Hidden mdDown>
									<ToggleScreenShareButton disabled={isReconnecting} />
								</Hidden>
							)}
							<Hidden mdDown>
								<Menu />
							</Hidden>
						</Grid>
					</Grid>
					<Hidden mdDown>
						<Grid style={{ flex: 1 }}>
							<Grid container justifyContent="flex-end">
								{!hideReadyButton && (
									<Button
										className={classes.readyButton}
										disabled={disableReadyButton}
										onClick={() => readyNotification()}
									>
										I'm Ready
									</Button>
								)}
								{!hideStartServiceButton && (
									<Button className={classes.startServiceButton} onClick={() => startService()}>
										Start Service
									</Button>
								)}
								{!hideEndServiceButton && (
									<Button
										className={classes.endServiceButton}
										onClick={() => setConfirmEndServiceOpen(true)}
									>
										End Service
									</Button>
								)}
								<InviteButton
									className={classes.inviteButton}
									onClick={() => setInviteOpen(true)}
								/>
							</Grid>
						</Grid>
					</Hidden>
				</Grid>
			</header>
			<InviteDialog
				open={inviteOpen}
				onClose={() => {
					setInviteOpen(false);
				}}
			/>
			<ConfirmationDialog
				title="Disconnect"
				text="Are you sure you want to disconnect?"
				actionTitle="YES"
				onActionClick={() => {
					room?.disconnect();
				}}
				open={confirmDisconnectOpen}
				onClose={() => {
					setConfirmDisconnectOpen(false);
				}}
			/>
			<ConfirmationDialog
				title="End Service"
				text="Are you sure you want to end service and disconnect?"
				actionTitle="YES"
				onActionClick={() => {
					endService();
				}}
				open={confirmEndServiceOpen}
				onClose={() => {
					setConfirmEndServiceOpen(false);
				}}
			/>
		</>
	);
}
