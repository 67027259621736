import React from 'react';

import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import EndCallButton from './Buttons/EndCallButton';
import Menu from './Menu/Menu';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		background: 'white',
		paddingLeft: '1em',
		display: 'none',
		height: `52px`,
		[theme.breakpoints.down('md')]: {
			display: 'flex',
		},
	},
	endCallButton: {
		height: '28px',
		fontSize: '0.85rem',
		padding: '0 0.6em',
	},
	settingsButton: {
		[theme.breakpoints.down('md')]: {
			height: '28px',
			minWidth: '28px',
			border: '1px solid rgb(136, 140, 142)',
			padding: 0,
			margin: '0 1em',
		},
	},
}));

export default function MobileTopMenuBar() {
	const classes = useStyles();

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			className={classes.container}
		>
			<div>
				<EndCallButton className={classes.endCallButton} />
				<Menu buttonClassName={classes.settingsButton} />
			</div>
		</Grid>
	);
}
