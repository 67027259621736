import React from 'react';

import { LocalTrackPublication, Participant, RemoteTrackPublication, Track } from 'twilio-video';

import { IVideoTrack } from '../common/types';
import useTrack from '../hooks/useTrack';
import VideoTrack from './VideoTrack';

interface PublicationProps {
	publication: LocalTrackPublication | RemoteTrackPublication;
	participant: Participant;
	isLocalParticipant?: boolean;
	videoOnly?: boolean;
	videoPriority?: Track.Priority | null;
	videoIsFlipped?: boolean;
}

export default function Publication({
	publication,
	isLocalParticipant,
	videoPriority,
	videoIsFlipped,
}: PublicationProps) {
	const track = useTrack(publication);

	if (!track) return null;

	// Even though we only have one case here, let's keep this switch() in case
	// we even need to add a 'data' case for rendering DataTracks.
	switch (track.kind) {
		case 'video':
			return (
				<VideoTrack
					track={track as IVideoTrack}
					priority={videoPriority}
					isLocal={!track.name.includes('screen') && isLocalParticipant}
					isFlipped={videoIsFlipped}
				/>
			);
		// All participant audio tracks are rendered in ParticipantAudioTracks.tsx
		default:
			return null;
	}
}
