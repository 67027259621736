export const BACKGROUND_FILTER_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
	width: 640,
	height: 480,
	frameRate: 24,
};

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
	width: 1280,
	height: 720,
	frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'RoshalRemote-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'RoshalRemote-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'RoshalRemote-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'RoshalRemote-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;

export enum SystemRole {
	OPERATOR = 'operator',
	GUIDE = 'guide',
	OBSERVER = 'observer',
}

export enum OrderState {
	PENDING = 0,
	REDISPATCH = 10,
	CANCELED = 20,
	IN_ESCALATION = 40,
	DECLINED = 50,
	INVALID = 99,
	DISPATCHABLE = 100,
	OFFERED = 150,
	EXPIRED_OFFER = 155,
	OFFER_DECLINED = 156,
	ASSIGNED = 200,
	ENROUTE = 250,
	ARRIVED = 300,
	SERVICING = 350,
	SERVICE_COMPLETE = 400,
	COMPLETE = 999,
	CANCELED_BILLING = 1021,
}
