import React from 'react';
import { useLocation } from 'react-router-dom';

import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import RoshalLogo from '../../assets/icons/RoshalLogo';
import { useAppState } from '../../common/state';
import UserMenu from './UserMenu';
import VideoLogo from './VideoLogo';
import Swoosh from './swoosh';

const useStyles = makeStyles((theme: Theme) => ({
	background: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: theme.palette.grey[200],
		height: '100%',
	},
	container: {
		position: 'relative',
		flex: '1',
	},
	innerContainer: {
		display: 'flex',
		width: '888px',
		height: '379px',
		borderRadius: '8px',
		boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
		overflow: 'hidden',
		position: 'relative',
		margin: 'auto',
		[theme.breakpoints.down('md')]: {
			display: 'block',
			height: 'auto',
			width: 'calc(100% - 40px)',
			margin: 'auto',
			maxWidth: '400px',
		},
	},
	swooshContainer: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundImage: Swoosh,
		backgroundSize: 'cover',
		width: '296px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100px',
			backgroundPositionY: '140px',
		},
	},
	logoContainer: {
		position: 'absolute',
		width: '210px',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			'display': 'flex',
			'alignItems': 'center',
			'width': '90%',
			'textAlign': 'initial',
			'& svg': {
				height: '64px',
			},
		},
	},
	roshalLogo: {
		position: 'absolute',
		top: 0,
		left: 0,
		margin: '20px',
	},
	content: {
		background: 'white',
		width: '100%',
		padding: '3em 4em',
		flex: 1,
		[theme.breakpoints.down('md')]: {
			padding: '2em',
		},
	},
	title: {
		color: 'white',
		margin: '1em 0 0',
		[theme.breakpoints.down('md')]: {
			margin: 0,
			fontSize: '1.1rem',
		},
	},
}));

interface IntroContainerProps {
	children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
	const classes = useStyles();
	const { user } = useAppState();
	const location = useLocation();

	return (
		<div className={classes.background}>
			<RoshalLogo className={classes.roshalLogo} />
			{user && location.pathname !== '/login' && <UserMenu />}
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.swooshContainer}>
						<div className={classes.logoContainer}>
							<VideoLogo />
							<Typography variant="h6" className={classes.title}>
								Roshal Health Telehealth
							</Typography>
						</div>
					</div>
					<div className={classes.content}>{props.children}</div>
				</div>
			</div>
		</div>
	);
};

export default IntroContainer;
