import React from 'react';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import {
	LocalAudioTrack,
	LocalVideoTrack,
	Participant,
	RemoteAudioTrack,
	RemoteVideoTrack,
} from 'twilio-video';

import AvatarIcon from '../assets/icons/AvatarIcon';
import ScreenShareIcon from '../assets/icons/ScreenShareIcon';
import { useAppState } from '../common/state';
import { format_participant_id } from '../common/utils';
import useIsTrackSwitchedOff from '../hooks/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from '../hooks/useParticipantIsReconnecting';
import usePublications from '../hooks/usePublications';
import useTrack from '../hooks/useTrack';
import AudioLevelIndicator from './AudioLevelIndicator';
import NetworkQualityLevel from './NetworkQualityLevel';
import PinIcon from './PinIcon';

const borderWidth = 2;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			'isolation': 'isolate',
			'position': 'relative',
			'display': 'flex',
			'alignItems': 'center',
			'height': '150px',
			'overflow': 'hidden',
			'marginBottom': '0.5em',
			'& video': {
				objectFit: 'contain !important',
			},
			'borderRadius': '4px',
			'border': `4px solid rgb(245, 248, 255)`,
			'paddingTop': `calc(${(9 / 16) * 100}% - 4px)`,
			'background': 'black',
			[theme.breakpoints.down('md')]: {
				height: 90,
				width: `160px`,
				marginRight: '8px',
				marginBottom: '0',
				fontSize: '12px',
				paddingTop: `88px`,
			},
		},
		innerContainer: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		},
		infoContainer: {
			position: 'absolute',
			zIndex: 2,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			height: '100%',
			width: '100%',
			background: 'transparent',
			top: 0,
		},
		avatarContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			background: 'black',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: 1,
			[theme.breakpoints.down('md')]: {
				'& svg': {
					transform: 'scale(0.7)',
				},
			},
		},
		reconnectingContainer: {
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			background: 'rgba(40, 42, 43, 0.75)',
			zIndex: 1,
		},
		screenShareIconContainer: {
			'background': 'rgba(0, 0, 0, 0.5)',
			'padding': '0.18em 0.3em',
			'marginRight': '0.3em',
			'display': 'flex',
			'& path': {
				fill: 'white',
			},
		},
		identity: {
			background: 'rgba(0, 0, 0, 0.5)',
			color: 'white',
			padding: '0.18em 0.3em 0.18em 0',
			margin: 0,
			display: 'flex',
			alignItems: 'center',
		},
		infoRowBottom: {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'absolute',
			bottom: 0,
			left: 0,
		},
		videoControlTop: {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'absolute',
			top: 0,
			right: 0,
		},
		typography: {
			color: 'white',
			[theme.breakpoints.down('md')]: {
				fontSize: '0.75rem',
			},
		},
		hideParticipant: {
			display: 'none',
		},
		cursorPointer: {
			cursor: 'pointer',
		},
		galleryView: {
			border: `4px solid rgb(245, 248, 255)`,
			borderRadius: '8px',
			height: '100%',
			[theme.breakpoints.down('md')]: {
				'position': 'relative',
				'width': '100%',
				'height': '100%',
				'padding': '0',
				'fontSize': '12px',
				'margin': '0',
				'& video': {
					objectFit: 'cover !important',
				},
			},
		},
		dominantSpeaker: {
			border: `solid ${borderWidth}px #7BEAA5`,
		},
	})
);

interface ParticipantInfoProps {
	participant: Participant;
	children: React.ReactNode;
	onClick?: () => void;
	onFlipClick?: () => void;
	isSelected?: boolean;
	isLocalParticipant?: boolean;
	hideParticipant?: boolean;
	isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
	participant,
	onClick,
	isSelected,
	children,
	isLocalParticipant,
	hideParticipant,
	isDominantSpeaker,
}: ParticipantInfoProps) {
	const publications = usePublications(participant);

	const audioPublication = publications.find(p => p.kind === 'audio');
	const videoPublication = publications.find(
		p => !p.trackName.includes('screen') && p.kind === 'video'
	);

	const isVideoEnabled = Boolean(videoPublication);
	const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

	const videoTrack = useTrack(videoPublication);
	const isVideoSwitchedOff = useIsTrackSwitchedOff(
		videoTrack as LocalVideoTrack | RemoteVideoTrack
	);

	const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
	const isParticipantReconnecting = useParticipantIsReconnecting(participant);

	const { isGalleryViewActive } = useAppState();

	const classes = useStyles();

	return (
		<div
			className={clsx(classes.container, {
				[classes.hideParticipant]: hideParticipant,
				[classes.cursorPointer]: Boolean(onClick),
				[classes.dominantSpeaker]: isDominantSpeaker,
				[classes.galleryView]: isGalleryViewActive,
			})}
			onClick={onClick}
			data-cy-participant={format_participant_id(participant.identity)}
		>
			<div className={classes.infoContainer}>
				<NetworkQualityLevel participant={participant} />
				{/* <div className={classes.videoControlTop}>
          <VideoFlipButton onClick={onFlipClick} />
        </div> */}
				<div className={classes.infoRowBottom}>
					{isScreenShareEnabled && (
						<span className={classes.screenShareIconContainer}>
							<ScreenShareIcon />
						</span>
					)}
					<span className={classes.identity}>
						<AudioLevelIndicator audioTrack={audioTrack} />
						<Typography variant="body1" className={classes.typography} component="span">
							{format_participant_id(participant.identity)}
							{isLocalParticipant && ' (You)'}
						</Typography>
					</span>
				</div>
				<div>{isSelected && <PinIcon />}</div>
			</div>
			<div className={classes.innerContainer}>
				{(!isVideoEnabled || isVideoSwitchedOff) && (
					<div className={classes.avatarContainer}>
						<AvatarIcon />
					</div>
				)}
				{isParticipantReconnecting && (
					<div className={classes.reconnectingContainer}>
						<Typography variant="body1" className={classes.typography}>
							Reconnecting...
						</Typography>
					</div>
				)}
				{children}
			</div>
		</div>
	);
}
