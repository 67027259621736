import React, { useEffect, useRef } from 'react';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Participant, Room as IRoom } from 'twilio-video';

import { SystemRole } from '../common/constants';
import { useAppState } from '../common/state';
import useScreenShareParticipant from '../hooks/useScreenShareParticipant';
import useVideoContext from '../hooks/useVideoContext';
import BackgroundSelectionDialog from './BackgroundSelectionDialog';
import { GalleryView } from './GalleryView';
import MainParticipant from './MainParticipant';
import { MobileGalleryView } from './MobileGalleryView';
import MyDevicesSelectionDialog from './MyDevicesSelectionDialog/MyDevicesSelectionDialog';
import { ParticipantAudioTracks } from './ParticipantAudioTracks';
import ParticipantList from './ParticipantList';

const useStyles = makeStyles((theme: Theme) => {
	const totalMobileSidebarHeight = `${108}px`;
	return {
		container: {
			position: 'relative',
			height: '100%',
			display: 'grid',
			gridTemplateColumns: `1fr ${300}px`,
			gridTemplateRows: '100%',
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: `100%`,
				gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
			},
		},
		leftDrawerOpen: {
			gridTemplateColumns: `1fl 300px 320px`,
		},
		rightDrawerOpen: {
			gridTemplateColumns: `1fr 300px 320px`,
		},
		galleryContainer: {
			marginTop: `${totalMobileSidebarHeight}`,
			height: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
			[theme.breakpoints.down('md')]: {
				marginTop: 0,
				height: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
			},
		},
	};
});

/**
 * This hook turns on speaker view when screensharing is active, regardless of if the
 * user was already using speaker view or gallery view. Once screensharing has ended, the user's
 * view will return to whatever they were using prior to screenshare starting.
 */

export function useSetSpeakerViewOnScreenShare(
	screenShareParticipant: Participant | undefined,
	room: IRoom | null,
	setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>,
	isGalleryViewActive: boolean
) {
	const isGalleryViewActiveRef = useRef(isGalleryViewActive);

	// Save the user's view setting whenever they change to speaker view or gallery view:
	useEffect(() => {
		isGalleryViewActiveRef.current = isGalleryViewActive;
	}, [isGalleryViewActive]);

	useEffect(() => {
		if (screenShareParticipant && screenShareParticipant !== room!.localParticipant) {
			// When screensharing starts, save the user's previous view setting (speaker or gallery):
			const prevIsGalleryViewActive = isGalleryViewActiveRef.current;
			// Turn off gallery view so that the user can see the screen that is being shared:
			setIsGalleryViewActive(false);
			return () => {
				// If the user was using gallery view prior to screensharing, turn gallery view back on
				// once screensharing stops:
				if (prevIsGalleryViewActive) {
					setIsGalleryViewActive(prevIsGalleryViewActive);
				}
			};
		}
	}, [screenShareParticipant, setIsGalleryViewActive, room]);
}

export default function Room() {
	const classes = useStyles();
	const { isBackgroundSelectionOpen, isMyDevicesSelectionOpen, room } = useVideoContext();
	const { isGalleryViewActive, setIsGalleryViewActive } = useAppState();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const screenShareParticipant = useScreenShareParticipant();

	// Here we switch to speaker view when a participant starts sharing their screen, but
	// the user is still free to switch back to gallery view.
	useSetSpeakerViewOnScreenShare(
		screenShareParticipant,
		room,
		setIsGalleryViewActive,
		isGalleryViewActive
	);

	useEffect(() => {
		if (room.localParticipant.identity.includes(SystemRole.GUIDE)) {
			setIsGalleryViewActive(false);
		}
	}, [room]);

	return (
		<div
			className={clsx(classes.container, {
				[classes.leftDrawerOpen]: isMyDevicesSelectionOpen,
				[classes.rightDrawerOpen]: isBackgroundSelectionOpen,
			})}
		>
			{/* 
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
			<ParticipantAudioTracks />

			{isGalleryViewActive ? (
				isMobile ? (
					<MobileGalleryView />
				) : (
					<GalleryView />
				)
			) : (
				<>
					<ParticipantList />
					<div className={classes.galleryContainer}>
						<MainParticipant />
					</div>
				</>
			)}

			<MyDevicesSelectionDialog />
			<BackgroundSelectionDialog />
		</div>
	);
}
