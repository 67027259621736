import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FormControl, MenuItem, Select, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LocalVideoTrack } from 'twilio-video';

import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../common/constants';
import useDevices from '../../hooks/useDevices';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack';
import useVideoContext from '../../hooks/useVideoContext';
import ConnectToggleButton from '../Buttons/ConnectToggleButton';
import LocalVideoPreview from '../PreJoinScreens/LocalVideoPreview';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		position: 'relative',
		height: 0,
		overflow: 'hidden',
		paddingTop: '100%',
		zIndex: 1,
	},
	innerContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	},
	identityContainer: {
		position: 'absolute',
		zIndex: 1,
	},
	identity: {
		background: 'rgba(0, 0, 0, 0.5)',
		color: 'white',
		padding: '0.18em 0.3em',
		margin: 0,
		display: 'flex',
		alignItems: 'center',
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'black',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 1,
		[theme.breakpoints.down('md')]: {
			'& svg': {
				transform: 'scale(0.7)',
			},
		},
	},
	button: {
		position: 'absolute',
		bottom: '10px',
		left: '30%',
		margin: '0 auto',
		width: '40%',
		zIndex: 1,
	},
	menuPaper: {
		maxHeight: 400,
		minWidth: 250,
		left: -10,
		overflow: 'hidden',
	},
	menu: {
		maxHeight: 400,
		zIndex: 6,
		overflow: 'hidden',
		fontSize: '0.75rem',
	},
}));

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function MyDeviceSelectorView({ identity }: { identity: string }) {
	const classes = useStyles();
	const query = useQuery();

	const { URLRoomName } = useParams<{ URLRoomName?: string }>();

	const { videoInputDevices } = useDevices();
	const { getAudioAndVideoTracks, localTracks } = useVideoContext();
	const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

	useEffect(() => {
		getAudioAndVideoTracks().catch(error => {
			console.log('Error acquiring local media:');
			console.dir(error);
		});

		if (isAudioEnabled) {
			toggleAudioEnabled();
		}
	}, [getAudioAndVideoTracks, isAudioEnabled, toggleAudioEnabled]);

	const localVideoTrack = localTracks.find(track => track.kind === 'video') as
		| LocalVideoTrack
		| undefined;
	const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
	const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
		window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
	);
	const localVideoInputDeviceId =
		mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

	function replaceTrack(newDeviceId: string) {
		// Here we store the device ID in the component state. This is so we can re-render this component display
		// to display the name of the selected device when it is changed while the users camera is off.
		setStoredLocalVideoDeviceId(newDeviceId);
		window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
		localVideoTrack?.restart({
			...(DEFAULT_VIDEO_CONSTRAINTS as {}),
			deviceId: { exact: newDeviceId },
		});
	}

	return (
		<div style={{ border: '1px solid black', background: 'white', width: '100%', height: '100%' }}>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<FormControl fullWidth size="medium">
						<Select
							onChange={e => replaceTrack(e.target.value as string)}
							value={localVideoInputDeviceId || ''}
							variant="outlined"
							MenuProps={{ className: classes.menuPaper }}
							className={classes.menu}
						>
							{videoInputDevices.map(device => (
								<MenuItem value={device.deviceId} key={device.deviceId} sx={{ fontSize: 'small' }}>
									{device.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<LocalVideoPreview identity={localVideoTrack?.kind || ''} />
				</div>
			</div>

			<ConnectToggleButton
				roomName={URLRoomName || ''}
				name={query.get('user_identity') || ''}
				className={classes.button}
			/>
		</div>
	);
}
