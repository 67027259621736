import { useRef, useState } from 'react';

import { ClassNames } from '@emotion/react';
import CollaborationViewIcon from '@mui/icons-material/AccountBox';
import GridViewIcon from '@mui/icons-material/Apps';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Menu, MenuItem, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import FlipCameraIcon from '../../assets/icons/FlipCameraIcon';
import { useAppState } from '../../common/state';
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle';
import { IconContainer } from './Menu';

interface MobileMenuProps {
	isReadyHidden?: boolean;
	isStartServiceHidden?: boolean;
	isEndServiceHidden?: boolean;
	onInviteClicked?: () => void;
	onContactClicked?: (event: any) => void;
	onReadyClicked?: () => void;
	onStartServiceClicked?: () => void;
	onEndServiceClicked?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mobileMenu: {
			margin: 0,
		},
	})
);

export default function MobileMenu({
	isReadyHidden,
	isStartServiceHidden,
	isEndServiceHidden,
	onInviteClicked,
	onContactClicked,
	onReadyClicked,
	onStartServiceClicked,
	onEndServiceClicked,
}: MobileMenuProps) {
	const classes = useStyles();
	const anchorRef = useRef<HTMLButtonElement>(null);
	const { setIsGalleryViewActive, isGalleryViewActive } = useAppState();
	const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<>
			<Button
				onClick={() => setMenuOpen(isOpen => !isOpen)}
				ref={anchorRef}
				className={classes.mobileMenu}
			>
				<MenuIcon />
			</Button>
			<Menu
				open={menuOpen}
				onClose={() => setMenuOpen(isOpen => !isOpen)}
				anchorEl={anchorRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: -55,
					horizontal: 'center',
				}}
			>
				{!isReadyHidden && (
					<MenuItem
						onClick={() => {
							onReadyClicked();
							setMenuOpen(false);
						}}
					>
						<Typography variant="body1">I'm Ready</Typography>
					</MenuItem>
				)}
				{!isStartServiceHidden && (
					<MenuItem
						onClick={() => {
							onStartServiceClicked();
							setMenuOpen(false);
						}}
					>
						<Typography variant="body1">Start Service</Typography>
					</MenuItem>
				)}
				{!isEndServiceHidden && (
					<MenuItem
						onClick={() => {
							onEndServiceClicked();
							setMenuOpen(false);
						}}
					>
						<Typography variant="body1">End Service</Typography>
					</MenuItem>
				)}
				<MenuItem
					onClick={() => {
						onInviteClicked();
						setMenuOpen(false);
					}}
				>
					<Typography variant="body1">Invite</Typography>
				</MenuItem>
				<MenuItem
					onClick={event => {
						onContactClicked(event);
						setMenuOpen(false);
					}}
				>
					<Typography variant="body1">Contact</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setIsGalleryViewActive(isGallery => !isGallery);
						setMenuOpen(false);
					}}
				>
					<IconContainer>
						{isGalleryViewActive ? (
							<CollaborationViewIcon style={{ fill: '#707578', width: '0.9em' }} />
						) : (
							<GridViewIcon style={{ fill: '#707578', width: '0.9em' }} />
						)}
					</IconContainer>
					<Typography variant="body1">
						{isGalleryViewActive ? 'Speaker View' : 'Gallery View'}
					</Typography>
				</MenuItem>
				{flipCameraSupported && (
					<MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
						<IconContainer>
							<FlipCameraIcon />
						</IconContainer>
						<Typography variant="body1">Flip Camera</Typography>
					</MenuItem>
				)}
			</Menu>
		</>
	);
}
