import React from 'react';

import { styled, Theme } from '@mui/material/styles';

import MenuBar from './components/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications';
import Room from './components/Room';
import useHeight from './hooks/useHeight';
import useRoomState from './hooks/useRoomState';

const Container = styled('div')({
	display: 'grid',
	gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
	overflow: 'hidden',
	paddingBottom: `0px`, // Leave some space for the footer
	background: 'black',
	[theme.breakpoints.down('md')]: {
		paddingBottom: `${0 + 52}px`, // Leave some space for the mobile header and footer
	},
}));

export default function App() {
	const roomState = useRoomState();

	// Here we would like the height of the main container to be the height of the viewport.
	// On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
	// not the viewport. This looks bad when the mobile browsers location bar is open.
	// We will dynamically set the height with 'window.innerHeight', which means that this
	// will look good on mobile browsers even after the location bar opens or closes.
	const height = useHeight();

	return (
		<Container style={{ height }}>
			{roomState === 'disconnected' ? (
				<PreJoinScreens />
			) : (
				<Main>
					<ReconnectingNotification />
					<RecordingNotifications />
					<MenuBar />
					<MobileTopMenuBar />
					<Room />
				</Main>
			)}
		</Container>
	);
}
