import { useState } from 'react';

import {
	DialogContent,
	Typography,
	Divider,
	Dialog,
	DialogActions,
	Button,
	Theme,
	DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		'width': '300px',
		'minHeight': '150px',
		[theme.breakpoints.down('sm')]: {
			width: 'calc(100vw - 32px)',
		},
		'& .inputSelect': {
			width: 'calc(100% - 35px)',
		},
	},
	button: {
		float: 'right',
	},
	paper: {
		[theme.breakpoints.down('sm')]: {
			margin: '16px',
		},
	},
	gutterBottom: {
		marginBottom: '1em',
	},
}));

export default function DeviceSelectionDialog({
	open,
	onClose,
	title,
	text,
	actionTitle,
	onActionClick,
}: {
	open: boolean;
	onClose: () => void;
	title: string;
	text: string;
	actionTitle: string;
	onActionClick: () => void;
}) {
	const classes = useStyles();

	const [isActionDisabled, setIsActionDisabled] = useState<boolean>(false);

	const handleAction = async () => {
		setIsActionDisabled(true);
		await onActionClick();
		setIsActionDisabled(false);
	};

	return (
		<Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
			<DialogTitle>{title}</DialogTitle>
			<Divider />
			<DialogContent className={classes.container}>
				<Typography variant="h5" className={classes.gutterBottom}>
					{text}
				</Typography>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button color="error" variant="contained" className={classes.button} onClick={onClose}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={isActionDisabled}
					className={classes.button}
					onClick={handleAction}
				>
					{actionTitle}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
