import React from 'react';

import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import useVideoContext from '../../hooks/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			'background': theme.danger,
			'color': 'white',
			'&:hover': {
				background: '#600101',
			},
		},
	})
);

export default function EndCallButton(props: { className?: string }) {
	const classes = useStyles();
	const { room } = useVideoContext();

	return (
		<Button
			onClick={() => room!.disconnect()}
			className={clsx(classes.button, props.className)}
			data-cy-disconnect
		>
			Disconnect
		</Button>
	);
}
