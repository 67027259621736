import React, { useState, useRef, useCallback } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Button, MenuItem, Link } from '@mui/material';
import Menu from '@mui/material/Menu';
import makeStyles from '@mui/styles/makeStyles';

import { useAppState } from '../../common/state';
import useVideoContext from '../../hooks/useVideoContext';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles({
	userContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		margin: '1em',
		display: 'flex',
		alignItems: 'center',
	},
	userButton: {
		color: 'white',
	},
	logoutLink: {
		color: 'white',
		cursor: 'pointer',
		padding: '10px 20px',
	},
});

const UserMenu: React.FC = () => {
	const classes = useStyles();
	const { user, signOut } = useAppState();
	const { localTracks } = useVideoContext();

	const [menuOpen, setMenuOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);

	const handleSignOut = useCallback(() => {
		localTracks.forEach(track => track.stop());
		signOut?.();
	}, [localTracks, signOut]);

	if (process.env.REACT_APP_SET_AUTH === 'passcode') {
		return (
			<div className={classes.userContainer}>
				<Link onClick={handleSignOut} className={classes.logoutLink}>
					Logout
				</Link>
			</div>
		);
	}

	if (process.env.REACT_APP_SET_AUTH === 'firebase') {
		return (
			<div className={classes.userContainer}>
				<UserAvatar user={user} />
				<Button
					onClick={() => setMenuOpen(isOpen => !isOpen)}
					ref={anchorRef}
					className={classes.userButton}
				>
					{user!.displayName}
					<ExpandMoreIcon />
				</Button>
				<Menu
					open={menuOpen}
					onClose={() => setMenuOpen(isOpen => !isOpen)}
					anchorEl={anchorRef.current}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<MenuItem onClick={handleSignOut}>
						<Typography variant="body1">Logout</Typography>
					</MenuItem>
				</Menu>
			</div>
		);
	}

	return null;
};

export default UserMenu;
