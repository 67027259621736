import React from 'react';

import { Participant as IParticipant } from 'twilio-video';

import ParticipantInfo from './ParticipantInfo';
import ParticipantTracks from './ParticipantTracks';

interface ParticipantProps {
	participant: IParticipant;
	videoOnly?: boolean;
	enableScreenShare?: boolean;
	onClick?: () => void;
	isSelected?: boolean;
	isLocalParticipant?: boolean;
	hideParticipant?: boolean;
	isDominantSpeaker?: boolean;
}

export function Participant({
	participant,
	videoOnly,
	enableScreenShare,
	onClick,
	isSelected,
	isLocalParticipant,
	hideParticipant,
	isDominantSpeaker,
}: ParticipantProps) {
	const [isFlipped, setIsFlipped] = React.useState<boolean>(false);

	return (
		<ParticipantInfo
			participant={participant}
			onClick={onClick}
			onFlipClick={() => {
				setIsFlipped(!isFlipped);
			}}
			isSelected={isSelected}
			isLocalParticipant={isLocalParticipant}
			hideParticipant={hideParticipant}
			isDominantSpeaker={isDominantSpeaker}
		>
			<ParticipantTracks
				participant={participant}
				videoOnly={videoOnly}
				videoIsFlipped={isFlipped}
				enableScreenShare={enableScreenShare}
				isLocalParticipant={isLocalParticipant}
			/>
		</ParticipantInfo>
	);
}

export default React.memo(Participant);
