export default function SmallCheckIcon() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_829_18244)">
				<rect x="4" y="2" width="16" height="16" rx="8" fill="white" />
				<rect width="12" height="12" transform="translate(6 4)" fill="white" fillOpacity="0.01" />
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.7952 7.95798C14.5221 7.68067 14.0783 7.68067 13.8052 7.95798L11.2852 10.5269L10.1948 9.41534C10.0582 9.2761 9.87928 9.20653 9.70034 9.20653C9.52034 9.20653 9.3414 9.2761 9.20481 9.41534C8.93173 9.69372 8.93173 10.1462 9.20481 10.4246L10.7906 12.0412C10.9231 12.1751 11.0979 12.25 11.2851 12.25C11.4723 12.25 11.6482 12.1751 11.7806 12.0412L14.7951 8.9671C15.0683 8.6898 15.0683 8.23636 14.7952 7.95798Z"
					fill="#14B053"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_829_18244"
					x="0"
					y="0"
					width="24"
					height="24"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="2" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.0705882 0 0 0 0 0.109804 0 0 0 0 0.176471 0 0 0 0.1 0"
					/>
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_829_18244" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_829_18244"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
}
