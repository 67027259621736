import { useCallback } from 'react';

import { LocalAudioTrack } from 'twilio-video';

import { useAppState } from '../common/state';
import useVideoContext from './useVideoContext';

export function useKrispToggle() {
	const { localTracks } = useVideoContext();
	const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
	const noiseCancellation = audioTrack && audioTrack.noiseCancellation;
	const vendor = noiseCancellation && noiseCancellation.vendor;
	const { setIsKrispEnabled } = useAppState();

	const toggleKrisp = useCallback(() => {
		if (noiseCancellation) {
			noiseCancellation[noiseCancellation.isEnabled ? 'disable' : 'enable']().then(() => {
				setIsKrispEnabled(noiseCancellation.isEnabled);
			});
		}
	}, [noiseCancellation, setIsKrispEnabled]);

	return { vendor, toggleKrisp };
}
