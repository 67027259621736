import React, { useRef, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { Track } from 'twilio-video';

import { IVideoTrack } from '../common/types';
import useMediaStreamTrack from '../hooks/useMediaStreamTrack';
import useVideoTrackDimensions from '../hooks/useVideoTrackDimensions';

const Video = styled('video')({
	width: '100%',
	height: '100%',
});

interface VideoTrackProps {
	track: IVideoTrack;
	isLocal?: boolean;
	priority?: Track.Priority | null;
	isFlipped?: boolean;
}

export default function VideoTrack({ track, isLocal, priority, isFlipped }: VideoTrackProps) {
	const ref = useRef<HTMLVideoElement>(null!);
	const mediaStreamTrack = useMediaStreamTrack(track);
	const dimensions = useVideoTrackDimensions(track);
	const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

	useEffect(() => {
		const el = ref.current;
		el.muted = true;

		if (!track) {
			return () => {};
		}

		if (track?.setPriority && priority) {
			track.setPriority(priority);
		}
		track.attach(el);
		return () => {
			track.detach(el);

			// This addresses a Chrome issue where the number of WebMediaPlayers is limited.
			// See: https://github.com/twilio/twilio-video.js/issues/1528
			el.srcObject = null;

			if (track.setPriority && priority) {
				// Passing `null` to setPriority will set the track's priority to that which it was published with.
				track.setPriority(null);
			}
		};
	}, [track, priority]);

	// The local video track is mirrored if it is not facing the environment.
	const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
	const isFrontFacingLocal = isLocal && isFrontFacing;
	const style = {
		transform:
			(isFlipped && !isFrontFacingLocal) || (!isFlipped && isFrontFacingLocal) ? 'scaleX(-1)' : '',
		objectFit:
			isPortrait || track?.name.includes('screen') || false
				? ('contain' as const)
				: ('contain' as const), // 'contain' or 'cover' depending on the track dimensions
	};

	return <Video ref={ref} style={style} />;
}
