import { Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			'background': theme.palette.error.main,
			'color': 'white',
			'&:hover': {
				background: theme.palette.error.dark,
			},
		},
	})
);

export default function SignOutButton(props: { className?: string; onClick?: () => void }) {
	const classes = useStyles();

	return (
		<Button
			onClick={() => props.onClick && props.onClick()}
			className={clsx(classes.button, props.className)}
			data-cy-disconnect
		>
			Disconnect
		</Button>
	);
}
