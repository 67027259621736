import React from 'react';

export default function VideoLogo() {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
		>
			<g>
				<path
					fill="white"
					d="M68.46,759.72c-0.04,0-0.09,0-0.13,0c0,53.54,0.05,107.07-0.1,160.61c-0.01,4.34,1.13,5.43,5.42,5.38
        c21.64-0.23,43.28-0.1,64.93-0.11c6.6,0,6.6-0.01,6.6-6.86c0-100.24-0.01-200.48,0-300.72c0-15.48,3.07-30.36,9.32-44.5
        c11.48-25.95,29.95-45.34,55.49-57.71c26.25-12.72,53.64-15,81.57-6.84c22,6.43,40.55,18.57,55.39,36.23
        c17,20.23,26.05,43.56,26.99,69.9c0.89,24.95-5.95,47.84-20.01,68.45c-13.57,19.9-31.76,33.98-54.25,42.69
        c-16.7,6.46-34.1,7.7-51.71,7.74c-19.53,0.04-39.05-0.01-58.58,0.04c-1.52,0-3.59-0.87-4.46,0.85c-0.96,1.89,1.14,2.8,2.21,3.87
        c66.13,66.17,132.3,132.31,198.38,198.53c1.84,1.85,3.66,2.35,5.6,1.45c11.71-5.45,23.65-10.51,34.93-16.76
        c39.95-22.15,74.36-50.97,102.93-86.74c29.77-37.28,50.7-79.03,63.38-124.9c6.9-24.95,10.83-50.47,11.89-76.37
        c1.2-29.35-1.04-58.48-7.34-87.16c-6.89-31.34-17.79-61.23-32.9-89.64c-23.33-43.87-54.68-80.93-93.84-111.29
        c-32.46-25.17-68.49-43.64-107.79-55.88c-23.98-7.47-48.39-12.4-73.35-14.56c-23.19-2.01-46.39-1.76-69.56,0.94
        c-20.37,2.38-40.38,6.45-59.97,12.36c-31.05,9.37-60.19,23.02-87.51,40.53c-4.55,2.92-4.6,3.27-0.85,6.9
        c1.4,1.35,2.7,2.81,4.04,4.23c14.72,15.6,29.46,31.19,44.14,46.84c1.76,1.88,3,1.94,5.34,0.55c12.56-7.45,25.49-14.24,39.08-19.65
        c24.42-9.73,49.59-15.9,75.87-18.45c19.4-1.89,38.61-1.47,57.76,0.87c20.97,2.56,41.39,7.75,61.25,15.23
        c30.73,11.59,58.49,28.14,82.9,49.93c35.61,31.79,61.04,70.45,76.44,115.79c9.35,27.52,13.7,55.81,13.83,84.64
        c0.06,12.06-0.6,24.26-2.43,36.38c-3.36,22.26-8.84,43.92-17.33,64.7c-9.67,23.67-22.41,45.66-38.45,65.69
        c-19.07,23.82-41.49,43.81-67.24,60.17c-2.47,1.57-3.89,1.49-5.77-0.55c-2.64-2.87-5.49-5.55-8.25-8.31
        c-13.69-13.69-27.41-27.36-41.05-41.1c-1.46-1.47-4.76-3.01-0.39-5.32c20.01-10.56,37.43-24.43,52.35-41.45
        c16.02-18.28,28.14-38.82,36.41-61.7c8.51-23.55,12.01-47.79,11.27-72.76c-0.69-23.32-5.64-45.76-14.77-67.11
        c-11.3-26.43-27.52-49.51-49.17-68.71c-30.35-26.93-65.8-42.57-105.84-47.34c-27.38-3.26-54.37-0.49-80.79,8.18
        c-27.96,9.18-52.47,23.81-73.72,44.02c-20.94,19.92-36.2,43.67-46.56,70.56c-6.26,16.26-11.21,33.15-11.41,50.54
        C68.08,654.6,68.46,707.16,68.46,759.72z M260.04,121.27c-15.33,0.6-30.64-0.56-46.08,1.19c-25.38,2.88-50.5,6.85-75.27,12.84
        c-21.44,5.18-42.29,12.44-62.93,20.23c-8.56,3.23-8.56,3.23-3.14,10.63c13.34,18.21,26.7,36.41,39.99,54.66
        c1.55,2.13,2.85,2.63,5.62,1.68c14.41-4.99,29-9.37,43.86-12.91c22.65-5.38,45.55-9.41,68.74-10.46
        c38.87-1.76,77.58-0.4,115.82,8.34c22.36,5.11,44.39,11.18,65.67,19.59c38.34,15.15,73.82,35.38,106.31,60.93
        c28.54,22.44,54,47.89,75.84,76.79c32.23,42.64,55.61,89.74,70.03,141.34c7.9,28.29,12.83,57.05,14.77,86.21
        c1.33,19.9,1.2,39.94-0.5,59.93c-2.13,24.98-6.11,49.56-12.42,73.84c-7.49,28.83-17.95,56.51-31.29,83.08
        c-12.62,25.15-27.8,48.75-45.27,70.82c-1.37,1.73-2.23,2.88-0.32,4.9c16.39,17.39,32.71,34.84,48.98,52.33
        c1.84,1.98,2.91,2.37,4.89-0.05c26.12-31.92,48.3-66.34,65.98-103.63c12.4-26.16,22.46-53.18,30.19-81.08
        c5.16-18.64,8.94-37.55,12.15-56.62c2.77-16.5,4.51-33.15,5.3-49.75c0.98-20.35,0.97-40.76-0.78-61.23
        c-1.84-21.56-4.34-42.96-8.48-64.15c-5.02-25.67-12.35-50.72-21.41-75.28c-11.89-32.21-26.93-62.84-45.16-91.89
        c-30.26-48.2-67.75-89.9-112.34-125.32c-26.66-21.17-55.24-39.24-85.53-54.62c-20.69-10.5-42.07-19.53-64.11-26.81
        c-13.56-4.48-27.33-8.33-41.14-12c-13.48-3.58-27.21-6.17-41-8.26c-13.26-2.01-26.55-4.16-39.91-5.1
        C284.82,120.59,272.41,121.27,260.04,121.27z"
				/>
			</g>
		</svg>
	);
}
