import React, { useState, useRef } from 'react';

import CollaborationViewIcon from '@mui/icons-material/AccountBox';
import GridViewIcon from '@mui/icons-material/Apps';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreIcon from '@mui/icons-material/MoreVert';
// import StartRecordingIcon from '../assets/icons/StartRecordingIcon';
// import StopRecordingIcon from '../assets/icons/StopRecordingIcon';
import SearchIcon from '@mui/icons-material/Search';
import {
	Button,
	styled,
	Theme,
	useMediaQuery,
	Menu as MenuContainer,
	MenuItem,
	Typography,
} from '@mui/material';
import { isSupported } from '@twilio/video-processors';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';

import BackgroundIcon from '../../assets/icons/BackgroundIcon';
import FlipCameraIcon from '../../assets/icons/FlipCameraIcon';
import InfoIconOutlined from '../../assets/icons/InfoIconOutlined';
import SettingsIcon from '../../assets/icons/SettingsIcon';
import { useAppState } from '../../common/state';
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle';
import useVideoContext from '../../hooks/useVideoContext';
import AboutDialog from '../AboutDialog';
import DeviceSelectionDialog from '../DeviceSelectionDialog/DeviceSelectionDialog';

export const IconContainer = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	width: '1.5em',
	marginRight: '0.3em',
});

export default function Menu(props: { buttonClassName?: string }) {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const [aboutOpen, setAboutOpen] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [settingsOpen, setSettingsOpen] = useState(false);

	const {
		/*isFetching, updateRecordingRules, roomType,*/ setIsGalleryViewActive,
		isGalleryViewActive,
	} = useAppState();
	//const isRecording = useIsRecording();
	const { /*room,*/ setIsBackgroundSelectionOpen, setIsMyDevicesSelectionOpen } = useVideoContext();

	const anchorRef = useRef<HTMLButtonElement>(null);
	const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

	return (
		<>
			<Button
				onClick={() => setMenuOpen(isOpen => !isOpen)}
				ref={anchorRef}
				className={props.buttonClassName}
				data-cy-more-button
			>
				<>
					More
					<ExpandMoreIcon />
				</>
			</Button>
			<MenuContainer
				open={menuOpen}
				onClose={() => setMenuOpen(isOpen => !isOpen)}
				anchorEl={anchorRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: isMobile ? -55 : 'bottom',
					horizontal: 'center',
				}}
			>
				<MenuItem onClick={() => setSettingsOpen(true)}>
					<IconContainer>
						<SettingsIcon />
					</IconContainer>
					<Typography variant="body1">Audio and Video Settings</Typography>
				</MenuItem>

				{isSupported && (
					<MenuItem
						onClick={() => {
							setIsBackgroundSelectionOpen(true);
							setIsMyDevicesSelectionOpen(false);
							setMenuOpen(false);
						}}
					>
						<IconContainer>
							<BackgroundIcon />
						</IconContainer>
						<Typography variant="body1">Backgrounds</Typography>
					</MenuItem>
				)}

				{flipCameraSupported && (
					<MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
						<IconContainer>
							<FlipCameraIcon />
						</IconContainer>
						<Typography variant="body1">Flip Camera</Typography>
					</MenuItem>
				)}

				{/* {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
            <Typography variant="body1">{isRecording ? 'Stop' : 'Start'} Recording</Typography>
          </MenuItem>
        )} */}

				<MenuItem
					onClick={() => {
						VideoRoomMonitor.toggleMonitor();
						setMenuOpen(false);
					}}
				>
					<IconContainer>
						<SearchIcon style={{ fill: '#707578', width: '0.9em' }} />
					</IconContainer>
					<Typography variant="body1">Room Monitor</Typography>
				</MenuItem>

				<MenuItem
					onClick={() => {
						setIsGalleryViewActive(isGallery => !isGallery);
						setMenuOpen(false);
					}}
				>
					<IconContainer>
						{isGalleryViewActive ? (
							<CollaborationViewIcon style={{ fill: '#707578', width: '0.9em' }} />
						) : (
							<GridViewIcon style={{ fill: '#707578', width: '0.9em' }} />
						)}
					</IconContainer>
					<Typography variant="body1">
						{isGalleryViewActive ? 'Speaker View' : 'Gallery View'}
					</Typography>
				</MenuItem>

				<MenuItem onClick={() => setAboutOpen(true)}>
					<IconContainer>
						<InfoIconOutlined />
					</IconContainer>
					<Typography variant="body1">About</Typography>
				</MenuItem>
			</MenuContainer>
			<AboutDialog
				open={aboutOpen}
				onClose={() => {
					setAboutOpen(false);
					setMenuOpen(false);
				}}
			/>
			<DeviceSelectionDialog
				open={settingsOpen}
				onClose={() => {
					setSettingsOpen(false);
					setMenuOpen(false);
				}}
			/>
		</>
	);
}
