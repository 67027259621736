import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, TextField, Grid, Button, InputLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { OrderState } from '../../common/constants';
import { useAppState } from '../../common/state';
import Snackbar from '../Snackbar';

const useStyles = makeStyles((theme: Theme) => ({
	gutterBottom: {
		marginBottom: '1em',
	},
	inputContainer: {
		'display': 'flex',
		'justifyContent': 'space-between',
		'margin': '1.5em 0 3.5em',
		'& div:not(:last-child)': {
			marginRight: '1em',
		},
		[theme.breakpoints.down('md')]: {
			margin: '1.5em 0 2em',
		},
	},
	textFieldContainer: {
		width: '100%',
	},
	continueButton: {
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
}));

interface RoomNameScreenProps {
	name: string;
	roomName: string;
	order: any;
	setName: (name: string) => void;
	setRoomName: (roomName: string) => void;
	setOrder: (order: any) => void;
	handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
	name,
	roomName,
	setName,
	handleSubmit,
}: RoomNameScreenProps) {
	const classes = useStyles();
	const { user } = useAppState();
	const { URLRoomName } = useParams<{ URLRoomName?: string }>();
	const [enableUserNameField, setEnableUserNameField] = useState<boolean>(false);
	const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState<boolean>(false);
	const [isCompleteSnackbarOpen, setIsCompleteSnackbarOpen] = useState<boolean>(false);

	const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	async function getOrderInfo(roomName: string) {
		try {
			const response = await fetch(`/api/order?order_gid=${roomName}`, {
				method: 'GET',
			});

			const result = await response.json();

			validateOrder(result);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	const validateOrder = orderInfo => {
		if (orderInfo && orderInfo.state) {
			if (
				orderInfo.state.code < OrderState.SERVICE_COMPLETE &&
				orderInfo.state.code !== OrderState.CANCELED
			) {
				setEnableUserNameField(true);
			} else if (orderInfo.state.code >= OrderState.SERVICE_COMPLETE) {
				setIsCompleteSnackbarOpen(true);
			} else {
				setIsErrorSnackbarOpen(true);
			}
		} else {
			setIsErrorSnackbarOpen(true);
		}
	};

	useEffect(() => {
		getOrderInfo(URLRoomName);
	}, []);

	const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

	return (
		<>
			<Snackbar
				open={isErrorSnackbarOpen}
				handleClose={() => setIsErrorSnackbarOpen(false)}
				headline={'Invalid Order'}
				message={
					'Check the telehealth link and reconnect.  Please contact Roshal Support at 1-888-Roshal0 for assistance.'
				}
				variant="error"
			/>
			<Snackbar
				open={isCompleteSnackbarOpen}
				handleClose={() => setIsCompleteSnackbarOpen(false)}
				headline={'Order Complete'}
				message={'Order has been completed.'}
				variant="info"
			/>
			<form onSubmit={handleSubmit}>
				<Typography variant="h5" className={classes.gutterBottom}>
					Sign In
				</Typography>
				<Typography variant="body1">To start, please enter your name.</Typography>
				<div className={classes.inputContainer}>
					{!hasUsername && (
						<div className={classes.textFieldContainer}>
							<InputLabel shrink htmlFor="input-user-name">
								Your Name
							</InputLabel>
							<TextField
								id="input-user-name"
								variant="outlined"
								type="text"
								fullWidth
								size="small"
								value={name}
								disabled={!enableUserNameField}
								onChange={handleNameChange}
							/>
						</div>
					)}
				</div>
				<Grid container justifyContent="flex-end">
					<Button
						variant="contained"
						type={roomName ? 'submit' : 'button'}
						color="primary"
						disabled={!name}
						className={classes.continueButton}
					>
						{roomName ? 'Configure Device' : 'Select Room'}
					</Button>
				</Grid>
			</form>
		</>
	);
}
