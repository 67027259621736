import { useEffect, useState } from 'react';

import { RemoteParticipant } from 'twilio-video';

import { sort_participants } from '../common/utils';
import useDominantSpeaker from './useDominantSpeaker';
import useVideoContext from './useVideoContext';

export default function useSpeakerViewParticipants() {
	const { room } = useVideoContext();
	const dominantSpeaker = useDominantSpeaker();
	const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));
	const localParticipant = room?.localParticipant;

	// When the dominant speaker changes, they are moved to the front of the participants array.
	// The rest of the participants get resorted based on role.
	useEffect(() => {
		if (dominantSpeaker) {
			setParticipants(prevParticipants => [
				dominantSpeaker,
				...sort_participants(
					prevParticipants.filter(participant => participant !== dominantSpeaker),
					localParticipant
				),
			]);
		}
	}, [dominantSpeaker]);

	useEffect(() => {
		if (room) {
			setParticipants(sort_participants(Array.from(room.participants.values()), localParticipant));

			const participantConnected = (participant: RemoteParticipant) =>
				setParticipants(prevParticipants =>
					sort_participants([...prevParticipants, participant], localParticipant)
				);
			const participantDisconnected = (participant: RemoteParticipant) =>
				setParticipants(prevParticipants =>
					sort_participants(
						prevParticipants.filter(p => p !== participant),
						localParticipant
					)
				);
			room.on('participantConnected', participantConnected);
			room.on('participantDisconnected', participantDisconnected);
			return () => {
				room.off('participantConnected', participantConnected);
				room.off('participantDisconnected', participantDisconnected);
			};
		}
	}, [room]);

	return participants;
}
