import React, { PropsWithChildren } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TwilioError } from 'twilio-video';

import enhanceMessage from '../common/enhanceMessage';

interface ErrorDialogProps {
	dismissError: Function;
	error: TwilioError | Error | null;
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
	const { message, code } = error || {};
	const enhancedMessage = enhanceMessage(message, code);

	return (
		<Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
			<DialogTitle>ERROR</DialogTitle>
			<DialogContent>
				<DialogContentText>{enhancedMessage}</DialogContentText>
				{Boolean(code) && (
					<pre>
						<code>Error Code: {code}</code>
					</pre>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => dismissError()} color="primary" autoFocus>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ErrorDialog;
