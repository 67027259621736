import { isPlainObject } from 'is-plain-object';
import { RemoteParticipant } from 'twilio-video';

import { SystemRole } from '../common/constants';

export const isMobile = (() => {
	if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
		return false;
	}
	return /Mobile/.test(navigator.userAgent);
})();

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
	if (!isPlainObject(obj)) return obj;

	const target: { [name: string]: any } = {};

	for (const key in obj) {
		const val = obj[key];
		if (typeof val !== 'undefined') {
			target[key] = removeUndefineds(val);
		}
	}

	return target as T;
}

export function sort_participants(participantArray: RemoteParticipant[], localParticipant: any) {
	return participantArray.sort(
		(a, b) =>
			convert_participant_role_to_number(a, localParticipant) -
			convert_participant_role_to_number(b, localParticipant)
	);
}

export function convert_participant_role_to_number(
	participant: RemoteParticipant,
	localParticipant: any
): number {
	if (!participant) {
		return 5;
	}

	// Special case for operators, guide has precedence
	if (localParticipant.identity.includes(SystemRole.OPERATOR)) {
		if (participant.identity.includes(SystemRole.GUIDE)) {
			return 0;
		} else if (participant.identity.includes(SystemRole.OPERATOR)) {
			return 1;
		}
	}

	switch (true) {
		case participant.identity.includes(SystemRole.OPERATOR):
			return 0;
		case participant.identity.includes(SystemRole.GUIDE):
			return 1;
		case participant.identity.includes(SystemRole.OBSERVER):
			return 2;
		default:
			return 3;
	}
}

export function format_participant_id(participant_id: string) {
	if (!participant_id) {
		return participant_id;
	}

	const [role, name] = participant_id.split('~');
	const capitalized_role = role.charAt(0).toUpperCase() + role.slice(1);
	return `${name} (${capitalized_role})`;
}

export function format_phone(phone: string) {
	if (!phone) {
		return phone;
	}

	phone = phone.replace(/\D/g, '');

	if (phone.length < 10) {
		return phone;
	}

	if (phone.startsWith('+')) {
		phone = phone.slice(1);
	}

	if (phone.startsWith('1')) {
		phone = phone.slice(1);
	}

	return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.slice(6)}`;
}

export async function getDeviceInfo() {
	const devices = await navigator.mediaDevices.enumerateDevices();

	return {
		audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
		videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
		audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
		hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
		hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
	};
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: 'camera' | 'microphone') {
	const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

	if (navigator.permissions) {
		try {
			const result = await navigator.permissions.query({ name: permissionName });
			return result.state === 'denied';
		} catch {
			return false;
		}
	} else {
		return false;
	}
}
