import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
	GaussianBlurBackgroundProcessor,
	ImageFit,
	isSupported,
	VirtualBackgroundProcessor,
} from '@twilio/video-processors';
import { LocalVideoTrack, Room } from 'twilio-video';

import Roshal_2A from '../assets/images/Roshal_2A.png';
import Roshal_2B from '../assets/images/Roshal_2B.png';
import Roshal_2C from '../assets/images/Roshal_2C.png';
import Roshal_2AThumb from '../assets/images/thumb/Roshal_2A.png';
import Roshal_2BThumb from '../assets/images/thumb/Roshal_2B.png';
import Roshal_2CThumb from '../assets/images/thumb/Roshal_2C.png';
import {
	BACKGROUND_FILTER_VIDEO_CONSTRAINTS,
	DEFAULT_VIDEO_CONSTRAINTS,
	SELECTED_BACKGROUND_SETTINGS_KEY,
	SystemRole,
} from '../common/constants';
import { Thumbnail } from '../components/BackgroundThumbnail';
import { useLocalStorageState } from './useLocalStorageState';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface BackgroundSettings {
	type: Thumbnail;
	index?: number;
}

const imageNames: string[] = ['Roshal A', 'Roshal B', 'Roshal C'];

const images = [Roshal_2AThumb, Roshal_2BThumb, Roshal_2CThumb];

const rawImagePaths = [Roshal_2A, Roshal_2B, Roshal_2C];

const isDesktopChrome = /Chrome/.test(navigator.userAgent);
const imageElements = new Map();

const getImage = (index: number): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		if (imageElements.has(index)) {
			return resolve(imageElements.get(index));
		}
		const img = new Image();
		img.onload = () => {
			imageElements.set(index, img);
			resolve(img);
		};
		img.onerror = reject;
		img.src = rawImagePaths[index];
	});
};

export const backgroundConfig = {
	imageNames,
	images,
};

const virtualBackgroundAssets = '/virtualbackground';
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

export default function useBackgroundSettings(
	videoTrack: LocalVideoTrack | undefined,
	room?: Room | null
) {
	const query = useQuery();
	const userRole = query.get('role') || '';
	let defaultBackgroundSettings: BackgroundSettings = { type: 'none', index: 0 };
	if (userRole === SystemRole.GUIDE) {
		defaultBackgroundSettings = { type: 'image', index: 0 };
	}

	const [backgroundSettings, setBackgroundSettings] = useLocalStorageState<BackgroundSettings>(
		SELECTED_BACKGROUND_SETTINGS_KEY,
		defaultBackgroundSettings
	);

	const setCaptureConstraints = useCallback(async () => {
		const { mediaStreamTrack, processor } = videoTrack ?? {};
		const { type } = backgroundSettings;
		if (type === 'none' && processor) {
			return mediaStreamTrack?.applyConstraints(DEFAULT_VIDEO_CONSTRAINTS as MediaTrackConstraints);
		} else if (type !== 'none' && !processor) {
			return mediaStreamTrack?.applyConstraints(
				BACKGROUND_FILTER_VIDEO_CONSTRAINTS as MediaTrackConstraints
			);
		}
	}, [backgroundSettings, videoTrack]);

	const removeProcessor = useCallback(() => {
		if (videoTrack && videoTrack.processor) {
			videoTrack.removeProcessor(videoTrack.processor);
		}
	}, [videoTrack]);

	const addProcessor = useCallback(
		(processor: GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor) => {
			if (!videoTrack || videoTrack.processor === processor) {
				return;
			}
			removeProcessor();
			videoTrack.addProcessor(processor, {
				inputFrameBufferType: 'video',
				outputFrameBufferContextType: 'webgl2',
			});
		},
		[videoTrack, removeProcessor]
	);

	useEffect(() => {
		if (!isSupported) {
			return;
		}
		// make sure localParticipant has joined room before applying video processors
		// this ensures that the video processors are not applied on the LocalVideoPreview
		const handleProcessorChange = async () => {
			if (!blurProcessor) {
				blurProcessor = new GaussianBlurBackgroundProcessor({
					assetsPath: virtualBackgroundAssets,
					// Disable debounce only on desktop Chrome as other browsers either
					// do not support WebAssembly SIMD or they degrade performance.
					debounce: !isDesktopChrome,
				});
				await blurProcessor.loadModel();
			}
			if (!virtualBackgroundProcessor) {
				virtualBackgroundProcessor = new VirtualBackgroundProcessor({
					assetsPath: virtualBackgroundAssets,
					backgroundImage: await getImage(0),
					// Disable debounce only on desktop Chrome as other browsers either
					// do not support WebAssembly SIMD or they degrade performance.
					debounce: !isDesktopChrome,
					fitType: ImageFit.Cover,
				});
				await virtualBackgroundProcessor.loadModel();
			}
			if (!room?.localParticipant) {
				return;
			}

			// Switch to 640x480 dimensions on browsers that
			// do not support WebAssembly SIMD to achieve optimum performance.
			const processor = blurProcessor || virtualBackgroundProcessor;
			// @ts-ignore
			if (!processor._isSimdEnabled) {
				await setCaptureConstraints();
			}

			if (backgroundSettings.type === 'blur') {
				addProcessor(blurProcessor);
			} else if (
				backgroundSettings.type === 'image' &&
				typeof backgroundSettings.index === 'number'
			) {
				virtualBackgroundProcessor.backgroundImage = await getImage(backgroundSettings.index);
				addProcessor(virtualBackgroundProcessor);
			} else {
				removeProcessor();
			}
		};
		handleProcessorChange();
	}, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor, setCaptureConstraints]);

	return [backgroundSettings, setBackgroundSettings] as const;
}
