import React, { SVGProps } from 'react';

export default function PersonIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 800 800"
			{...props}
		>
			<path
				d="M400,0C179.4,0,0,179.4,0,400s179.4,400,400,400s400-179.4,400-400S620.6,0,400,0z M303.4,224.7c24.4-25.8,58.7-40,96.6-40
	s71.9,14.3,96.4,40.3c24.8,26.3,36.8,61.7,34,99.7c-5.7,75.5-64.2,137-130.4,137s-124.8-61.5-130.4-137
	C266.8,286.2,278.8,250.8,303.4,224.7z M400,738.5c-91.3,0.1-178.7-36.9-242.3-102.3c18-25.7,41-47.6,67.6-64.3
	c49-31.4,111.1-48.7,174.7-48.7s125.7,17.3,174.7,48.7c26.6,16.7,49.6,38.6,67.6,64.3C578.7,701.6,491.3,738.5,400,738.5z"
			/>
		</svg>
	);
}
