import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import useMainParticipant from '../hooks/useMainParticipant';
import useParticipantsContext from '../hooks/useParticipantsContext';
import useScreenShareParticipant from '../hooks/useScreenShareParticipant';
import useSelectedParticipant from '../hooks/useSelectedParticipant';
import useVideoContext from '../hooks/useVideoContext';
import Participant from './Participant';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginTop: `80px`,
			overflowY: 'auto',
			background: 'rgb(79, 83, 85)',
			gridArea: '1 / 2 / 1 / 3',
			height: `calc(100% - 80px)`,
			zIndex: 5,
			[theme.breakpoints.down('md')]: {
				marginTop: `0px`,
				height: '100%',
				gridArea: '2 / 1 / 3 / 3',
				overflowY: 'initial',
				overflowX: 'auto',
				display: 'flex',
			},
		},
		transparentBackground: {
			background: 'transparent',
		},
		scrollContainer: {
			display: 'flex',
			height: '100%',
			justifyContent: 'center',
		},
		innerScrollContainer: {
			width: `100%`,
			height: '100%',
			padding: '1.5em 0',
			marginRight: '8px',
			[theme.breakpoints.down('md')]: {
				width: 'auto',
				padding: `8px`,
				display: 'flex',
			},
		},
	})
);

export default function ParticipantList() {
	const classes = useStyles();
	const { room } = useVideoContext();
	const localParticipant = room!.localParticipant;
	const { speakerViewParticipants } = useParticipantsContext();
	const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
	const screenShareParticipant = useScreenShareParticipant();
	const mainParticipant = useMainParticipant();
	const isRemoteParticipantScreenSharing =
		screenShareParticipant && screenShareParticipant !== localParticipant;

	if (speakerViewParticipants.length === 0) return null; // Don't render this component if there are no remote participants.

	return (
		<aside
			className={clsx(classes.container, {
				[classes.transparentBackground]: !isRemoteParticipantScreenSharing,
			})}
		>
			<div className={classes.scrollContainer}>
				<div className={classes.innerScrollContainer}>
					<Participant participant={localParticipant} isLocalParticipant={true} />
					{speakerViewParticipants.map(participant => {
						const isSelected = participant === selectedParticipant;
						const hideParticipant =
							participant === mainParticipant &&
							participant !== screenShareParticipant &&
							!isSelected;
						return (
							<Participant
								key={participant.sid}
								participant={participant}
								isSelected={participant === selectedParticipant}
								onClick={() => setSelectedParticipant(participant)}
								hideParticipant={hideParticipant}
							/>
						);
					})}
				</div>
			</div>
		</aside>
	);
}
