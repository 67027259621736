import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import App from './App';
import AppStateProvider, { useAppState } from './common/state';
import './common/types';
import ErrorDialog from './components/ErrorDialog';
import MyDeviceApp from './components/MyDeviceApp';
import { ParticipantProvider } from './components/ParticipantProvider';
import PrivateRoute from './components/PrivateRoute';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './hooks/useConnectionOptions';
import theme from './theme';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const VideoApp = () => {
	const { error, setError } = useAppState();
	const connectionOptions = useConnectionOptions();

	return (
		<VideoProvider options={connectionOptions} onError={setError}>
			<ErrorDialog dismissError={() => setError(null)} error={error} />
			<ParticipantProvider>
				<App />
			</ParticipantProvider>
		</VideoProvider>
	);
};

const SimpleVideoApp = () => {
	const { setError } = useAppState();
	const connectionOptions = useConnectionOptions();

	return (
		<VideoProvider options={{ ...connectionOptions, audio: false }} onError={setError}>
			<MyDeviceApp />
		</VideoProvider>
	);
};

export const ReactApp = () => (
	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<UnsupportedBrowserWarning>
				<Router>
					<AppStateProvider>
						<Switch>
							<PrivateRoute exact path="/">
								<VideoApp />
							</PrivateRoute>
							<PrivateRoute path="/room/:URLRoomName">
								<VideoApp />
							</PrivateRoute>
							<PrivateRoute path="/room_device/:URLRoomName">
								<SimpleVideoApp />
							</PrivateRoute>
							<Redirect to="/" />
						</Switch>
					</AppStateProvider>
				</Router>
			</UnsupportedBrowserWarning>
		</ThemeProvider>
	</StyledEngineProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
