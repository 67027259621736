import React from 'react';

import useMainParticipant from '../hooks/useMainParticipant';
import useScreenShareParticipant from '../hooks/useScreenShareParticipant';
import useSelectedParticipant from '../hooks/useSelectedParticipant';
import useVideoContext from '../hooks/useVideoContext';
import MainParticipantInfo from './MainParticipantInfo';
import ParticipantTracks from './ParticipantTracks';

export default function MainParticipant() {
	const mainParticipant = useMainParticipant();
	const { room } = useVideoContext();
	const localParticipant = room!.localParticipant;
	const [selectedParticipant] = useSelectedParticipant();
	const screenShareParticipant = useScreenShareParticipant();

	const videoPriority =
		(mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
		mainParticipant !== localParticipant
			? 'high'
			: null;

	return (
		/* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
		<MainParticipantInfo participant={mainParticipant}>
			<ParticipantTracks
				participant={mainParticipant}
				videoOnly
				enableScreenShare={mainParticipant !== localParticipant}
				videoPriority={videoPriority}
				isLocalParticipant={mainParticipant === localParticipant}
			/>
		</MainParticipantInfo>
	);
}
